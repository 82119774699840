import { createContext, FC, useContext, useEffect, useState } from 'react'

import { QueryObserverResult } from '@tanstack/react-query'
import { changeLanguage } from 'i18next'
import { useSearchParams } from 'next/navigation'
import { useTranslation } from 'react-i18next'

import { User } from '~/lib/api/models'
import { useUser } from '~/lib/api/useUser'
import { isLoggedIn } from '~/lib/utils/isLoggedIn'

// Global app context
// ------------------
export type TinyhoodContextStateType = {
  isReady: boolean
  isLoggedIn: boolean
  user: User | null
}

export type TinyhoodAppContextType = {
  isReady: boolean
  isLoggedIn: boolean
  user?: User | null
  userUpdatedAt: number
  refreshUser: () => Promise<QueryObserverResult<User | null, Error>>
  stickyUtms: Record<string, string>
}

export const defaultAppContext: TinyhoodContextStateType = {
  isReady: false,
  isLoggedIn: false,
  user: null,
}

export const TinyhoodAppContext = createContext<TinyhoodAppContextType | undefined>(undefined)

const REFRESH_USER_MINUTES = 10

export const AppContextProvider: FC = ({ children }) => {
  const { i18n } = useTranslation()
  const [isReady, setIsReady] = useState<boolean>(false)
  const [stickyUtms, setStickyUtms] = useState<Record<string, string>>({})

  const searchParams = useSearchParams()
  const {
    data: user,
    dataUpdatedAt: userUpdatedAt,
    isFetched: userFetched,
    refetch: refreshUser,
  } = useUser({
    refetchInterval: REFRESH_USER_MINUTES * 60 * 1000, // refetch the user every N minutes to keep our session live while the app is running
    staleTime: 0, // use staleTime: 0 to force the client to fetch the user immediately when the client hydrates to fetch the session / frontend cookies
  })

  useEffect(() => {
    if (userFetched) {
      setIsReady(true)
    }
  }, [userFetched, setIsReady])

  useEffect(() => {
    if (user && user.locale && i18n.language !== user.locale) {
      changeLanguage(user.locale)
    }
  }, [user])

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (!key.startsWith('utm_')) return
      setStickyUtms((stickyUtms) => {
        return { ...stickyUtms, [key]: value }
      })
    })
  }, [searchParams])

  return (
    <TinyhoodAppContext.Provider
      value={{
        isReady,
        isLoggedIn: isLoggedIn(user),
        stickyUtms,
        user,
        userUpdatedAt,
        refreshUser,
      }}
    >
      {children}
    </TinyhoodAppContext.Provider>
  )
}

export const useAppContext = (): TinyhoodAppContextType => {
  const context = useContext(TinyhoodAppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider')
  }
  return context
}
