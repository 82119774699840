interface ErrorScreenProps {
  message?: string
}

export default function ErrorScreen({
  message = 'An unexpected error occurred',
}: ErrorScreenProps) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <h1>{message}</h1>
      <p className="mt-2">
        Please contact{' '}
        <a href="mailto:support@tinyhood.com" target="_blank" rel="noreferrer">
          support@tinyhood.com
        </a>{' '}
        if it continues.
      </p>
    </div>
  )
}
