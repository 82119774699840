import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'

function createAxios() {
  const apiHost = process.env.NEXT_PUBLIC_API_BASE_URL
  if (!apiHost) {
    throw new Error('NEXT_PUBLIC_API_BASE_URL not defined')
  }

  const axiosInstance = axios.create({
    baseURL: apiHost,
  })
  axiosBetterStacktrace(axiosInstance)

  axiosInstance.defaults.xsrfCookieName = 'csrf_token_v2'
  axiosInstance.defaults.xsrfHeaderName = 'X-CSRF-Token'
  axiosInstance.defaults.withXSRFToken = true
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.post = { Accept: 'application/json' }

  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      // try/catch all this so an issue doesn't break error reporting
      try {
        const statusStr = !!error.response ? error.response.status : 'No Response'

        // Change message from "Request failed with status code XXX" to include the code, method, path
        error.message = `[${statusStr}] [${error.config.method?.toUpperCase()}] ${error.config.url}`

        // Also add a Sentry breadcrumb for extra details
        Sentry.addBreadcrumb({
          category: 'api',
          level: 'error',
          message: error.message,
          data: {
            path: error.config.url,
            method: error.config.method,
            status: statusStr,
            errorData: error.response?.data?.error,
          },
        })
      } catch (axioInterceptorException) {
        Sentry.captureException(axioInterceptorException)
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}

const instance = createAxios()
export default instance
