import * as Sentry from '@sentry/nextjs'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
    queryCache: new QueryCache({
      onError: (err, _query) => {
        Sentry.captureException(err)
      },
    }),
    mutationCache: new MutationCache({
      onError: (err, _variables, _context, _mutation) => {
        Sentry.captureException(err)
      },
    }),
  })
}
