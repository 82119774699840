import { QueryClient, UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig, isAxiosError } from 'axios'
import api from './api'
import { User } from './models'

export const USER_QUERY_KEY = 'user'

async function getUser(config?: AxiosRequestConfig) {
  try {
    const response = await api.get<User>('/api/v6/user', config)
    return response.data
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 401) {
      return null
    } else {
      throw e
    }
  }
}

export function useUser(
  options: Omit<
    UndefinedInitialDataOptions<User | null, Error, User | null, string[]>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    ...options,
    queryKey: [USER_QUERY_KEY],
    queryFn: getUser,
  })
}

export async function fetchUser(queryClient: QueryClient, config?: AxiosRequestConfig) {
  return await queryClient.fetchQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: () => getUser(config),
  })
}
