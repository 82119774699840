import '../styles/globals.css'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useState } from 'react'
import ErrorBoundaryFallback from '~/components/elements/errors/ErrorBoundaryFallback'
import { createQueryClient } from '~/lib/createQueryClient'
import { AppContextProvider } from '~/lib/state_management/context/appContext'
import '~/lib/utils/i18n'
import { getOrigin } from '~/lib/utils/url'

const DynamicAnalyticsWithNoSSR = dynamic(() => import('../lib/utils/analytics/analytics'), {
  ssr: false,
})

function MyApp({ Component, pageProps }: AppProps) {
  const origin = getOrigin()

  // With SSR always create the QueryClient inside the app
  // https://tanstack.com/query/v5/docs/framework/react/guides/ssr
  const [queryClient] = useState(createQueryClient)

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppContextProvider>
              <Head>
                <title property="og:title">Tinyhood</title>
                <meta property="og:image" key="og_image" content={`${origin}/api/og`} />
                <meta property="og:image:secure_url" content="https://www.tinyhood.com/api/og" />
                <meta
                  property="og:image:alt"
                  content="Two parents watching a video on a tablet. The name Tinyhood is shown in the bottom right corner."
                />
                <meta
                  name="keywords"
                  content="Tinyhood, Tinyhood, Inc., Social Networking, Lifestyle, ios apps, Mom, Parent, mom group, pregnancy app, mom app, baby, tiny hood, Parenting advice, parenting expert, advice, sleep training, breastfeeding, nutrition, potty training, behavior issues"
                />
                <meta
                  name="description"
                  property="og:description"
                  key="description"
                  content="Online classes for the modern parent — taught by leading experts.  Learn breastfeeding, infant through child CPR, sleep training, methods for soothing a fussy baby, newborn care, and more. Kids don't come with a manual, but this is pretty close."
                />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, viewport-fit=cover,user-scalable=no"
                ></meta>
                <meta name="p:domain_verify" content="1ad20ed82d7624c00eaadf6ba803e85b" />
                <link rel="image_src" href={`${origin}/images/icons/tinyhood-logo-180.png`} />
                <link rel="icon" href="/favicon.ico" sizes="32x32" />
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="apple-touch-icon" href="/images/icons/tinyhood-logo-180.png" />
                <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />
              </Head>
              <DynamicAnalyticsWithNoSSR />
              <Component {...pageProps} />
            </AppContextProvider>
          </LocalizationProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default MyApp
