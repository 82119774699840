var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3572480e2ce8bb004a05c754986e38ce3602a4bd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { SENTRY_IGNORED_API_ERRORS } from '~/lib/api/sentryIgnoredApiErrors.ts'

const SENTRY_ENV =
  process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV || process.env.NODE_ENV
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: SENTRY_ENV,
  dsn: SENTRY_DSN || 'https://31ecbec2057c4ed4ad51eba7d426489f@o427875.ingest.sentry.io/6440294',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Workaround for CORS bug in Sentry sdk
  // https://github.com/getsentry/sentry-javascript/issues/6192
  integrations: [
    new Sentry.BrowserTracing({
      shouldCreateSpanForRequest: (_) => false,
    }),
  ],
  ignoreErrors: [
    // https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee/66260608#66260608
    'ResizeObserver loop limit exceeded',
    'Extension context invalidated.',
    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    ...SENTRY_IGNORED_API_ERRORS,
  ],
})
