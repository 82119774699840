import ErrorScreen from './ErrorScreen'

// Simple implementation of the Sentry "error boundary" fallback ui
// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/

export default function ErrorBoundaryFallback(errorData: {
  error: Error
  componentStack: string
  eventId: string
  resetError(): void
}) {
  return <ErrorScreen />
}
